import {Component, OnDestroy} from '@angular/core';
import {ServicesStatus} from '../../../models';
import {StatusDaoService} from '../status-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';

@Component({
    selector: 'app-k8s',
    templateUrl: './k8s.component.html',
    styleUrls: ['./k8s.component.scss', '../status.component.scss']
})
export class K8sComponent extends ComponentCleaner implements OnDestroy {

    servicesStatus: ServicesStatus;
    private clearInterval;
    appType = AppType;

    constructor(
        private statusDaoService: StatusDaoService,
        private dialog: MatDialog) {
        super();
        this.getServicesStatus();
        this.clearInterval = setInterval(() => {
            this.getServicesStatus();
        }, 60000);
    }

    ngOnDestroy(): void {
        if (this.clearInterval) {
            clearTimeout(this.clearInterval);
            this.clearInterval = null;
        }
    }

    private getServicesStatus(): void {
        this.statusDaoService.getServicesStatus().subscribe((servicesStatus) => {
            if (servicesStatus) {
                this.servicesStatus = servicesStatus;
            }
        });
    }

    rolloutApp(appName: string, appType: AppType): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div>Tem certeza que deseja reiniciar o <b>' + appName + '</b>? ' +
                    '<br><br> Para reiniciar digite <u>REINICIAR</u> no campo abaixo</div>',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Reiniciar',
                icon: 'error_outline',
                confirmFieldValue: 'REINICIAR'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                console.log(`Restarting app ${appName}`);
                this.statusDaoService.rolloutApp({value: appName}, appType).subscribe((valueWrapper) => {
                    if (valueWrapper) {
                        console.log(`Restarted app ${valueWrapper.value}`);
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }
}

export enum AppType {
    DEPLOYMENT = 'DEPLOYMENT',
    DAEMON_SET = 'DAEMON_SET',
    STATEFUL_SET = 'STATEFUL_SET'
}


