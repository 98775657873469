<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header fxLayout="column" fxLayoutAlign="start stretch">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 7px;">
            <div>Cluster Kubernetes</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="scroll-content">


            <div class="page-loading-shade" *ngIf="!servicesStatus">
                <mat-spinner *ngIf="!servicesStatus"></mat-spinner>
            </div>
            <div *ngIf="servicesStatus">
                <div class="success-desc" *ngIf="!servicesStatus.hasError">
                    <mat-icon class="success-icon" style="font-size: 22px;">check_circle</mat-icon>
                    <b class="tit-status-item">Todos os sistemas estão funcionando corretamente</b>
                </div>

                <div class="error-desc" *ngIf="servicesStatus.hasError">
                    <mat-icon class="error-icon">error</mat-icon>
                    <span class="tit-status-item">Alguns sistemas não estão funcionando</span>
                </div>

                <div class="mt-20">
                    <div class="mb-10">
                        <div fxLayout="row" class="row-app">
                            <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                    style="width: 36px;height: 41px;"
                                    [matMenuTriggerFor]="menu1">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu1="matMenu" style="max-width: 372px">
                                <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-api', appType.STATEFUL_SET)">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Restart</span>
                                </button>
                            </mat-menu>
                            <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.api">
                                <mat-icon class="error-icon">error</mat-icon>
                                <div class="tit-status-item">API</div>
                                <div class="tit-status-item">{{ servicesStatus.api }}</div>
                            </div>
                            <div class="success  pt-12" *ngIf="!servicesStatus.api">
                                <mat-icon class="success-icon">check_circle</mat-icon>
                                <span class="tit-status-item">API</span>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div class="mb-10">
                        <div fxLayout="row" class="row-app">
                            <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                    style="width: 36px;height: 41px;"
                                    [matMenuTriggerFor]="menu2">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu2="matMenu" style="max-width: 372px">
                                <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-monitoring', appType.STATEFUL_SET)">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Restart</span>
                                </button>
                            </mat-menu>
                            <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.monitoring">
                                <mat-icon class="error-icon">error</mat-icon>
                                <div class="tit-status-item">Monitoring</div>
                                <div class="tit-status-item">{{ servicesStatus.monitoring }}</div>
                            </div>
                            <div class="success pt-12" *ngIf="!servicesStatus.monitoring">
                                <mat-icon class="success-icon">check_circle</mat-icon>
                                <span class="tit-status-item">Monitoring</span>
                            </div>
                        </div>
                    </div>

                    <div class="mb-10">
                        <div fxLayout="row" class="row-app">
                            <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                    style="width: 36px;height: 41px;"
                                    [matMenuTriggerFor]="menu3">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu3="matMenu" style="max-width: 372px">
                                <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-terminal', appType.STATEFUL_SET)">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Restart</span>
                                </button>
                            </mat-menu>
                            <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.terminal">
                                <div class="tit-status-item">Terminal</div>
                                <div class="tit-status-item">{{ servicesStatus.terminal }}</div>
                            </div>
                            <div class="success pt-12" *ngIf="!servicesStatus.terminal">
                                <mat-icon class="success-icon">check_circle</mat-icon>
                                <span class="tit-status-item">Terminal</span>
                            </div>
                        </div>
                    </div>

                    <div class="mb-10">
                        <div fxLayout="row" class="row-app">
                            <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                    style="width: 36px;height: 41px;"
                                    [matMenuTriggerFor]="menu4">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu4="matMenu" style="max-width: 372px">
                                <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-custom-layout', appType.STATEFUL_SET)">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Restart</span>
                                </button>
                            </mat-menu>
                            <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.customLayout">
                                <mat-icon class="error-icon">error</mat-icon>
                                <div class="tit-status-item">Custom Layout</div>
                                <div class="tit-status-item">{{ servicesStatus.customLayout }}</div>
                            </div>
                            <div class="success pt-12" *ngIf="!servicesStatus.customLayout">
                                <mat-icon class="success-icon">check_circle</mat-icon>
                                <span class="tit-status-item">Custom Layout</span>
                            </div>
                        </div>
                    </div>


                    <div class="mb-10">
                        <div fxLayout="row" class="row-app">
                            <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                    style="width: 36px;height: 41px;"
                                    [matMenuTriggerFor]="menu5">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu5="matMenu" style="max-width: 372px">
                                <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-gateway-manager', appType.STATEFUL_SET)">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Restart</span>
                                </button>
                            </mat-menu>
                            <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.gatewayManager">
                                <mat-icon class="error-icon">error</mat-icon>
                                <div class="tit-status-item">Gateway Manager (R2 Security)</div>
                                <div class="tit-status-item">{{ servicesStatus.gatewayManager }}</div>
                            </div>
                            <div class="success pt-12" *ngIf="!servicesStatus.gatewayManager">
                                <mat-icon class="success-icon">check_circle</mat-icon>
                                <span class="tit-status-item">Gateway Manager (R2 Security)</span>
                            </div>
                        </div>
                    </div>


                    <div class="mb-10"  fxLayout="row">
                        <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                style="width: 36px;height: 41px;"
                                [matMenuTriggerFor]="menu6">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu6="matMenu" style="max-width: 372px">
                            <button *appIsAdmin mat-menu-item (click)="rolloutApp('r2cloud-ips', appType.DAEMON_SET)">
                                <mat-icon>refresh</mat-icon>
                                <span>Restart</span>
                            </button>
                        </mat-menu>
                        <div class="bg-warn text-on-warn pt-12" *ngIf="servicesStatus.ipsManager">
                            <mat-icon class="error-icon">error</mat-icon>
                            <div class="tit-status-item">Ips Manager (R2 Security)</div>
                            <div class="tit-status-item">{{ servicesStatus.ipsManager }}</div>
                        </div>
                        <div class="success pt-12"  *ngIf="!servicesStatus.ipsManager">
                            <mat-icon class="success-icon">check_circle</mat-icon>
                            <span class="tit-status-item">Ips Manager (R2 Security)</span>
                        </div>
                    </div>


                    <div class="mb-10"  fxLayout="row">

                        <button *appIsAdmin class="send-message-button" mat-icon-button aria-label="Menu"
                                style="width: 36px;height: 41px;"
                                [matMenuTriggerFor]="menu7">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu7="matMenu" style="max-width: 372px">
                            <button *appIsAdmin mat-menu-item (click)="rolloutApp('image-cache', appType.STATEFUL_SET)">
                                <mat-icon>refresh</mat-icon>
                                <span>Restart</span>
                            </button>
                        </mat-menu>

                        <div class="bg-warn text-on-warn pt-12"  *ngIf="servicesStatus.imageCache">
                            <mat-icon class="error-icon">error</mat-icon>
                            <div class="tit-status-item">Image Cache</div>
                            <div class="tit-status-item">{{ servicesStatus.imageCache }}</div>
                        </div>
                        <div class="success pt-12" *ngIf="!servicesStatus.imageCache">
                            <mat-icon class="success-icon">check_circle</mat-icon>
                            <span class="tit-status-item">Image Cache</span>
                        </div>
                    </div>


                    <div class="mb-10" style="margin-top: 32px !important;" *ngIf="servicesStatus.hasError && servicesStatus.others?.length > 0">
                        <div  style="margin-left: 36px;margin-top: 22px;"  *ngFor="let item of servicesStatus.others" class="bg-warn text-on-warn">
                            <mat-icon class="error-icon">error</mat-icon>
                            <div class="tit-status-item">{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </mat-card-content>
</mat-card>
