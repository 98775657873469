import {Injectable} from '@angular/core';
import {BaseSearchDao} from '../../base-search-dao';
import {ServicesStatus, SessionNumbers} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {ValueWrapper} from '../../helpers/value-wrapper';
import {AppType} from './k8s/k8s.component';


@Injectable()
export class StatusDaoService extends BaseSearchDao<ServicesStatus> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'status');
    }

    getServicesStatus(): Observable<ServicesStatus> {
        return this.http.get<ServicesStatus>(this.url + '/k8s');
    }

    getSessionNumbers(): Observable<SessionNumbers> {
        return this.http.get<SessionNumbers>(this.url + '/session-numbers');
    }

    rolloutApp(valueWrapper: ValueWrapper, appType: AppType): Observable<ValueWrapper> {
        const rolloutStrategy: { [key in AppType]?: (valueWrapper: ValueWrapper) => Observable<ValueWrapper> } = {
            [AppType.STATEFUL_SET]: this.rolloutStatefulSet.bind(this),
            [AppType.DAEMON_SET]: this.rolloutDaemonSet.bind(this),
            [AppType.DEPLOYMENT]: this.rolloutDeployment.bind(this),
        };
        const rolloutFn = rolloutStrategy[appType];
        if (!rolloutFn) {
            throw new Error(`Unsupported appType: ${appType}`);
        }
        return rolloutFn(valueWrapper);
    }

    rolloutStatefulSet(valueWrapper: ValueWrapper): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/rollout-stateful-set', valueWrapper);
    }

    rolloutDaemonSet(valueWrapper: ValueWrapper): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/rollout-daemon-set', valueWrapper);
    }

    rolloutDeployment(valueWrapper: ValueWrapper): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/rollout-deployment', valueWrapper);
    }
}
