import {Injectable, NgZone} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {AiFile, AiSoftwareCompanyFile} from '../../../models-ai';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {PageEvent} from '@angular/material/paginator';
import {SearchCriteria} from '../../../models';
import {Page} from '../../../helpers/Page';

@Injectable({
    providedIn: 'root'
})
export class AiSoftwareCompanyFileDaoService extends BaseDao<any> implements DataSource<AiSoftwareCompanyFile> {
    private aiURL = '';

    private subject = new BehaviorSubject<AiSoftwareCompanyFile[]>([]);
    private totalSubject = new BehaviorSubject<number>(0);

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator,
        private ngZone: NgZone) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    connect(collectionViewer: CollectionViewer): Observable<AiSoftwareCompanyFile[] | ReadonlyArray<AiSoftwareCompanyFile>> {
        this.subject = new BehaviorSubject<AiSoftwareCompanyFile[]>([]);
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.totalSubject.complete();
    }

    clearAllData(): void {
        this.subject.next(null);
        this.totalSubject.next(null);
    }
















    load(field: string, value: string, page: PageEvent, isDefault: boolean): void {
        this.clearAllData();
        if (this.subject && this.totalSubject) {
            // tslint:disable-next-line:no-shadowed-variable
            this.search({field: field, value: value, limit: page.pageSize, page: page.pageIndex, fieldOrder: 'id', order: 'desc'}, isDefault).subscribe(value => {
                const totalElements = value?.totalElements;
                this.totalSubject.next(totalElements);
                this.subject.next(value?.content);
            });
        }
    }

    get total$(): Observable<number> {
        if (!this.totalSubject) {
            this.totalSubject = new BehaviorSubject<number>(0);
        }
        return this.totalSubject.asObservable();
    }

    addFile(file: AiSoftwareCompanyFile): void {
        const aiSoftwareCompanyFile = this.subject.value;
        aiSoftwareCompanyFile.unshift(file);
        this.subject.next(aiSoftwareCompanyFile);
    }

    updateFile(file: AiSoftwareCompanyFile): void {
        const aiSoftwareCompanyFile = this.subject.value;
        const index = aiSoftwareCompanyFile.findIndex((f) => (f.id === null || f.id < 0) && f.name === file.name && f.size === file.size);
        if (index !== -1) {
            aiSoftwareCompanyFile[index] = file;
            this.subject.next(aiSoftwareCompanyFile);
        }
    }

    delete(file: AiSoftwareCompanyFile): Observable<void> {
        this.removeFileFromListById(file.id);
        return this.http.delete(this.aiURL + 'ai-software-company-file/', file, true);
    }

    private removeFileFromListById(aiFileId: number): void {
        this.subject.next(this.subject.value.filter(item => item.id !== aiFileId));
    }
    removeFileFromListByName(file: AiSoftwareCompanyFile): void {
        this.subject.next(this.subject.value.filter(item => item.name !== file.name));
    }

    getAll(): AiSoftwareCompanyFile[] {
        return this.subject.getValue();
    }

    private search(searchCriteria: SearchCriteria, isDefault: boolean): Observable<Page<AiSoftwareCompanyFile>> {
        return this.http.put<Page<AiSoftwareCompanyFile>>(this.aiURL + 'ai-software-company-file/search', searchCriteria, true);
    }

    uploadFile(file: File, aiSoftwareCompanyFile: AiSoftwareCompanyFile): Observable<AiSoftwareCompanyFile> {
        aiSoftwareCompanyFile.id = null;
        const formData: FormData = new FormData();
        formData.append('aiSoftwareCompanyFile', JSON.stringify(aiSoftwareCompanyFile));
        formData.append('file', file, file.name);
        return this.http.upload<AiSoftwareCompanyFile>(formData, this.aiURL + 'ai-software-company-file/', true);
    }


    downloadProcessedFile(file: AiFile): Observable<Blob> {
        return this.http.get(this.aiURL + 'ai-software-company-file/download-processed-file/' + file.id + '/' + file.processedFileUUID, null, 'blob', true);
    }

    downloadFile(file: AiFile): Observable<Blob> {
        return this.http.get(this.aiURL + 'ai-software-company-file/download-file/' + file.id + '/' + file.fileUUID, null, 'blob');
    }




}
